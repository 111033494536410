export default [
  {
    value: "show",
    text: "",
    width: 25,
    align: "center",
    sortable: false,
    copyable: false,
  },
  {
    value: "cameras",
    text: "Cameras",
    width: 400,
    sortable: true,
    filterable: true,
    toStringFn: (item, key) =>
      item[key]?.map((camera) => camera.name).join(",") || "-",
  },
  {
    value: "camerasStatuses",
    text: "Status",
    width: 60,
    sortable: true,
    filterable: true,
  },
  {
    value: "camerasCrStatuses",
    text: "CR Status",
    width: 60,
    sortable: true,
    filterable: true,
  },
  {
    value: "host",
    text: "Camera IP",
    width: 175,
    sortable: true,
    filterable: true,
  },
  {
    value: "cameraHttpPort",
    text: "HTTP Port",
    align: "center",
    width: 85,
    sortable: true,
    filterable: true,
  },
  {
    value: "nvrHttpPort",
    text: "NVR HTTP Port",
    align: "center",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "nvrDeviceId",
    text: "Device ID",
    align: "center",
    width: 85,
    sortable: true,
  },
  {
    value: "count",
    text: "# Cameras",
    align: "center",
    width: 75,
    sortable: true,
  },
]
