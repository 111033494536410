export default [
  {
    value: "name",
    text: "Name",
    width: 175,
    sortable: true,
    filterable: true,
  },
  {
    value: "exid",
    text: "ID",
    width: 175,
    sortable: true,
    filterable: true,
  },
  {
    value: "status",
    text: "Status",
    width: 150,
    sortable: true,
    align: "center",
    filterable: true,
  },
  {
    value: "userFullname",
    text: "Owner",
    width: 175,
    sortable: true,
    filterable: true,
  },
  {
    value: "cloudRecordingStatus",
    text: "CR Status",
    width: 85,
    align: "center",
    sortable: true,
    filterable: true,
  },
  {
    value: "shareCount",
    text: "Shared Count",
    width: 95,
    sortable: true,
    align: "center",
    filterable: true,
  },
  {
    value: "createdAt",
    text: "Created At",
    width: 175,
    sortable: true,
    filterable: true,
  },
]
