<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="duplicate cameras"
          :headers="headers"
          sort-by="host"
          item-key="subId"
          :unique-id="true"
          :provider="provider"
        >
          <template #item.cameras="{ item }">
            <CamerasColumn
              :item="item"
              :items-per-line="1"
              @open-camera-dialog="
                (camera) => cameraDialogStore.openDialog({ camera })
              "
            />
          </template>
          <template #item.camerasStatuses="{ item }">
            <span v-for="(camera, index) in item.cameras" :key="index">
              <span :class="getStatusColor(camera.cameraStatus)">
                {{ getStatusText(camera.cameraStatus) }}
              </span>
              <br />
            </span>
          </template>
          <template #item.camerasCrStatuses="{ item }">
            <span v-for="(camera, index) in item.cameras" :key="index">
              <span :class="getCrStatusColor(camera.crStatus)">
                {{ getCrStatusText(camera.crStatus) }}
              </span>
              <br />
            </span>
          </template>
          <template #item.show="{ item }">
            <span class="cursor-pointer" @click="openCamerasTable(item)">
              <v-icon small> far fa-eye </v-icon>
            </span>
          </template>
        </ReportTable>
        <v-dialog
          v-model="dialog"
          max-width="1100"
          @click:outside="closeDuplicateCameraList()"
        >
          <v-card>
            <v-card-text class="pa-4">
              <v-data-table
                v-model="selected"
                :headers="cameraListHeaders"
                :items="cameraList"
                class="admin-data-table"
                dense
                show-select
                :loading="loading"
                loading-text="Please wait..."
                calculate-widths
                :mobile-breakpoint="0"
                :footer-props="{
                  'items-per-page-options': [100, 500, 1000],
                }"
                unselectable
              >
                <template #item.status="{ item }">{{
                  snakeCaseToTitleCase(item.status)
                }}</template>
                <template #item.userFullname="{ item }">
                  <span class="primary--text cursor-pointer">
                    {{ item.userFullname }}
                  </span>
                </template>
              </v-data-table>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                :disabled="selected.length < 2"
                text
                @click="mergeDuplicateCameraList()"
              >
                Merge
              </v-btn>
              <v-btn color="primary" text @click="closeDuplicateCameraList()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="mergeDialog" persistent max-width="290">
          <v-card>
            <v-card-title> Select Super Camera </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-select
                v-model="superCamera"
                class="mt-3"
                :items="selected"
                return-object
                :item-text="
                  (item) => item.name + ' - Shares (' + item.shareCount + ')'
                "
                item-value="id"
                label="Super Camera"
              />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!superCamera"
                color="primary"
                text
                @click="mergeCameras()"
              >
                Merge
              </v-btn>
              <v-btn color="primary" text @click="mergeDialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import duplicateCamerasHeaders from "@/components/duplicateCameras/duplicateCamerasHeaders"
import cameraListHeader from "@/components/duplicateCameras/duplicateCamerasListHeaders"
import { CloudRecordingsStatuses } from "@/components/constants.js"
import CameraUtils from "@/mixins/cameraUtils"
import CamerasColumn from "@/components/CamerasColumn"
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useReportStore } from "@/stores/report"

export default {
  components: {
    CamerasColumn,
    ReportTable,
  },
  mixins: [CameraUtils],
  data: () => {
    return {
      loading: true,
      mergeDialog: false,
      superCamera: null,
      headers: duplicateCamerasHeaders,
      cameraListHeader,
      cameraList: [],
      selected: [],
      dialog: false,
      selectedDuplicateRecord: null,
    }
  },
  head() {
    return {
      title: "Admin - Duplicate Cameras",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useReportStore),
    provider() {
      return AdminApi.cameras.getDuplicateCameras
    },
  },
  mounted() {
    this.reportStore.searchFilters = {
      fields: ["host", "camera_http_port", "nvr_http_port", "nvr_device_id"],
    }
  },
  methods: {
    async openCamerasTable(item) {
      this.selectedDuplicateRecord = item
      let cameraExids = item.cameras.map((c) => c.exid)
      await AdminApi.cameras
        .getCameras({ params: { cameraExids: cameraExids } })
        .then((result) => {
          this.dialog = true
          this.cameraList = result.items
          this.loading = false
        })
        .catch((error) => {
          this.$notifications.error({
            text: "Could not load duplicate cameras",
            error,
          })
        })
    },
    closeDuplicateCameraList() {
      this.dialog = false
      this.cameraList = []
    },
    mergeDuplicateCameraList() {
      this.mergeDialog = true
    },
    async mergeCameras() {
      let shareeCameras = this.selected.filter(
        (camera) => camera.id !== this.superCamera.id
      )
      await AdminApi.cameras
        .mergeDuplicateCameras({
          masterCamera: this.superCamera,
          shareeCameras: shareeCameras,
        })
        .then(() => {
          this.$notifications.success("Cameras have been merged.")
          if (this.cameraList.length === this.selected.length) {
            this.cameraList = []
            this.dialog = false
          } else {
            this.openCamerasTable(this.selectedDuplicateRecord)
          }
          this.mergeDialog = false
          this.selected = []
          this.superCamera = null
        })
        .catch((error) => {
          this.$notifications.error({
            text: "Merge duplicate cameras failed!",
            error,
          })
        })
    },
    getCrStatusText(value) {
      return (
        CloudRecordingsStatuses.find((status) => status.value === value)
          ?.name || "-"
      )
    },
    getCrStatusColor(value) {
      return (
        CloudRecordingsStatuses.find((status) => status.value === value)
          ?.class || ""
      )
    },
  },
}
</script>
~~/components/duplicateCameras/duplicate-cameras-header.js~~/components/duplicateCameras/duplicate-cameras-list-header.js
